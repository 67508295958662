import React from 'react';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';

import { LeftArrow, RightArrow } from './Arrows';

import Tabs from '../../../Tabs';

export const OverviewTab = () => {
  return (
    <Tabs.Tab>
      <h4>Overview</h4>
      <div className="line-clamp">Full map</div>
    </Tabs.Tab>
  );
};

export const DailyItineraryTabs = ({ itineraries, itineraryType }) => {
  const scrollToLastElement = (VisibilityContext) => {
    const lastElement = VisibilityContext.getItemElementByIndex(itineraries.length - 1);
    VisibilityContext.scrollToItem(lastElement);
  };

  return (
    <div className="itinerary-tabs-wrapper">
      <ScrollMenu
        scrollContainerClassName="scroll-container"
        itemClassName="scroll-item"
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        onInit={(VisibilityContext) => {
          if (itineraryType === 'postExtended') {
            scrollToLastElement(VisibilityContext);
          }
        }}
      >
        {itineraries.map((itinerary, index) => {
          return (
            <Tabs.Tab key={index} className="w-full h-full">
              <h4>Day {itinerary.day}</h4>
              <div className="line-clamp itinerary-short-description">{itinerary.shortDescription}</div>
            </Tabs.Tab>
          );
        })}
      </ScrollMenu>
    </div>
  );
};
