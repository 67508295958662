

// Usage of this function:
// Input: (sortformatDateYear("Wed Jun 04 2025 05:45:00 GMT+0545"));
 // Output: June 4th, 2025
export function sortformatDateYear(dateString) {
    const date = new Date(dateString);
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    let formattedDate = date.toLocaleDateString('en-US', options);

    const day = date.getDate();
    const suffix =
        day === 1 || day === 21 || day === 31 ? 'st' :
        day === 2 || day === 22 ? 'nd' :
        day === 3 || day === 23 ? 'rd' : 'th';

    formattedDate = formattedDate.replace(/\d+/, `${day}${suffix}`);
    return formattedDate;
}

// Usage of this function:
 // Input: (addDaysToDate("Wed Jun 04 2025 05:45:00 GMT+0545", 3));
 // Output: Sun Jun 07 2025 05:45:00 GMT+0545 (Nepal Time)

export function addDaysToDate(dateString, daysToAdd = 1) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd); 
    return date;
}


