import React from 'react';

import Tabs from '../../../Tabs';
import Grid from '../../../Grid';
import { OverviewTab, DailyItineraryTabs } from './Tabs';
import { OverviewPanel, DailyItineraryPanels } from './Panels';

const Itinerary = ({ itineraryData, tourStartDate, header, itineraryType, locationPins }) => {
  return (
    <>
      <Grid>
        <Grid.Col md="2 / span 10" xs="1 / span 2">
          {header}
        </Grid.Col>
      </Grid>

      <Tabs className="daily-itinerary-tab">
        <Grid>
          <Grid.Col
            md="2 / span 10"
            xs="1 / span 2"
            sx={{
              padding: {
                xs: '0 15px',
                md: '0',
              },
            }}
          >
            <Tabs.List>
              <OverviewTab />
              <DailyItineraryTabs itineraries={itineraryData} itineraryType={itineraryType} />
            </Tabs.List>
          </Grid.Col>
        </Grid>

        <Tabs.Panels>
          <OverviewPanel locationPins={locationPins} />
          <DailyItineraryPanels itineraryType = {itineraryType} itineraries={itineraryData} tourStartDate={tourStartDate} />
        </Tabs.Panels>
      </Tabs>
    </>
  );
};

export default Itinerary;
