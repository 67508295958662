import React from 'react';
import { format } from 'date-fns';
import { Box } from '@mui/material';

import Grid from '../../Grid';
import { sortformatDateYear } from '../../../utils/dateformat';

const Jumbotron = ({ title, subtitle, bgImage, tourSlug, tourStartDate, tourEndDate, isPrivateTour }) => {
  const fTourStartDate = sortformatDateYear(tourStartDate)
  const fTourEndDate = sortformatDateYear(tourEndDate)

  const backgroundImage = bgImage?.fluid?.src || bgImage?.url;
  return (
    <section className="jumbotron">
      <Grid>
        <Grid.Col md="2 / span 10" xs="1 / span 2">
          <Box
            sx={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}>
            <Box className="jumbotron-content">
              <div>
                <Box>
                  <h1>{title}</h1>
                  <p>{subtitle}</p>
                </Box>

                <h2>{isPrivateTour ? `${fTourStartDate} - ${fTourEndDate}`:""}</h2>

                <a
                  href={isPrivateTour ? `/tours/GV/booking` : `/contact`}
                  className="btn btn-md btn-primary-ii btn-tour-jumbotron">
                  Book Now
                </a>
              </div>
            </Box>
          </Box>
        </Grid.Col>
      </Grid>
    </section>
  );
};

export default Jumbotron;
